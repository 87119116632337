"use client";

import firebase from "firebase/compat/app";
import "firebase/compat/database";
import {useEffect, useState} from "react";

export const useFirebaseAuthToken = () => {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken(true);
        setToken(token);
      } else {
        setToken(undefined);
      }
    });

    return () => unsubscribe();
  }, []);

  return token;
};
